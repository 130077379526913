import deepOrange from "@mui/material/colors/deepOrange";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const Theme = createTheme({
  palette: {
    primary: deepOrange,
  },
});

export default Theme;
